import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

// core components
import { Box, Card, CardActionArea, Typography } from "@material-ui/core";

import { OipRecord } from "oip/OipRecord";
import { config } from "ddx.config.js";

import { DDX } from "components/ddx/ddx";

import { MnemonicsStoreConsumer } from "../../../MnemonicsStore.jsx";
class SectionCard extends React.Component {
  state = {
    details: {},
    txid: "",
    sig: ""
  };

  async componentDidMount() {
    const { data } = this.props;
    const oip = new OipRecord();
    const details = await oip.resolveAll(data);
    const txid = data.meta.txid;
    const sig = data.meta.signed_by;
    this.setState({
      details,
      txid,
      sig
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.bullet}>
        {this.state.details.oipRecord && (
          <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
            <Box display="flex" flexGrow={1} width="100%">
              <Link
                className={classes.cardLink}
                to={"/record/" + this.state.txid}
                style={{ width: "100%" }}
              >
                <CardActionArea>
                  <Box p={1} display="flex" flexGrow={1} width="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      flexGrow={1}
                      width="100%"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexGrow={1}
                        width="100%"
                      >
                        <Box pr={1} display="flex" flexGrow={1} width="100%">
                          <DDX
                            color="textPrimary"
                            variant="h5"
                            component="basic"
                            field="name"
                            template={config.templates.basic}
                            details={this.state.details}
                          />
                        </Box>
                        <Box flexShrink={1}>
                          <DDX
                            classeName={classes.media}
                            component="thumb"
                            ipfsUrl={config.ipfs.apiUrl}
                            template={config.templates.basic}
                            details={this.state.details}
                          />
                        </Box>
                      </Box>
                      <Box pt={1}>
                        <DDX
                          // noWrap={true}
                          component="basic"
                          field="description"
                          template={config.templates.basic}
                          details={this.state.details}
                          variant="caption"
                          style={{ overflowWrap: "break-word" }}
                          color="textPrimary"
                          sliceContentFn={content => {
                            if (content) {
                              const maxWords = 20;
                              let newContent = content
                                .split(" ")
                                .slice(0, maxWords)
                                .join(" ");
                              if (content.split(" ").length > maxWords) {
                                content += " ...";
                              }
                              return newContent;
                            }
                            return "";
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardActionArea>
              </Link>
            </Box>

            <Box
              p={1}
              display="flex"
              flexDirection="column"
              flexShrink={1}
              alignItems="flex-end"
            >
              <Box p={1} justifyContent="flex-end">
                <DDX
                  // noWrap={true}
                  variant="caption"
                  style={{ overflowWrap: "break-word" }}
                  color="textPrimary"
                  component="oip"
                  field="manufacturer"
                  template={config.templates.device}
                  details={this.state.details}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                alignContent="flex-end"
              >
                <Box
                  flexShrink={1}
                  pr={1}
                  justifyContent="flex-end"
                  flexDirection="column"
                >
                  {this.context.addresses.includes(this.state.sig) && (
                    <VpnKeyIcon
                      style={{ color: "darkgreen", fontSize: "12px" }}
                    ></VpnKeyIcon>
                  )}
                </Box>
                <Box
                  display="flex"
                  width="100%"
                  flexDirection="column"
                  flexGrow={1}
                  pr={1}
                  // alignContent="flex-end"
                >
                  <Box display="flex" flexDirection="row-reverse">
                    <Typography
                      style={{
                        display: "block",
                        fontSize: "8px",
                        overflowWrap: "break-word",
                        fontStyle: "italic",
                        fontFamily: "monospace"
                      }}
                      color="textPrimary"
                    >
                      {this.state.txid}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row-reverse">
                    <Typography
                      style={{
                        display: "block",
                        fontSize: "8px",
                        overflowWrap: "break-word",
                        fontStyle: "italic",
                        fontFamily: "monospace"
                      }}
                      color="textPrimary"
                    >
                      Owned by: {this.state.sig}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Card>
    );
  }
}

SectionCard.contextType = MnemonicsStoreConsumer;

SectionCard.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object
};

export default withStyles()(SectionCard);
