import { Logger } from "loglevel-colored-prefix";
import { OipApi } from "oip/OipApi";

const loglevel = "warn";
const shaRegEx = new RegExp(/\b[A-Fa-f0-9]{64}\b/);

class OipRecord {
  constructor(
    config = {
      daemonApiUrl: "https://api.oip.io/oip/o5/"
    }
  ) {
    this.config = config;
    this.data = {};
    this.log = new Logger(loglevel);
  }

  async resolveAll(rawData) {
    const log = this.log.getLogger("OipRecord::resolveAll");
    log.info(`Let's deeply resolve this record`)
    log.info(rawData)
    if (!rawData) {
      return {};
    }
    if (rawData.hasOwnProperty("record")) {

      const details = rawData.record.details;
      const listTemplates = Object.keys(details);
      const data = {
        oipRecord: true
      };
      for (const template of listTemplates) {
        data[template] = {};
        const fields = Object.keys(details[template]);
        for (const field of fields) {
          const value = details[template][field];
          if (typeof value === "string") {
            data[template][field] = await this.resolveValue(value);
          } else if (typeof value === "object") {
            const values = [];
            for (const v of value) {
              log.info(`value in array: ${v}`);
              const resolved = await this.resolveValue(v);
              values.push(resolved);
            }
            data[template][field] = values;
          } else {
            data[template][field] = value;
          }
        }
      }
      return data;
    }
    return {};
  }

  async resolveValue(value) {
    const log = this.log.getLogger("OipRecord::resolveValue");
    if (value.match(shaRegEx)) {
      log.info(`Found hash ${value}`);
      const api = new OipApi();
      const extraValue = await api.getRecord(value);
      log.info(`in the hash ${JSON.stringify(extraValue, null, " ")}`);
      const final = await this.resolveAll(extraValue.results[0]);
      final.id = value;
      return final;
    } else {
      return value;
    }
  }
}

export { OipRecord };
