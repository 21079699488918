import React from "react";
import { Wallet } from "@oipwg/hdmw";
import { config } from "ddx.config.js";
import { OipApi } from "oip/OipApi";
const { decodeDescriptor } = require("oip-protobufjs/lib/builders");

export const MnemonicsContext = React.createContext();
const api = new OipApi(config.daemonApiUrl);

class MnemonicsStoreProvider extends React.Component {
  state = {
    wallet: null,
    mnemonics: "",
    addresses: [],
    walletState: 0,
    templateDetails: {},
    templatesLoaded: 0
  };

  loadKeys = mnemonics => {
    this.setState({ mnemonics, walletState: 1, wallet: undefined }, () => {
      setTimeout(() => {
        try {
          const wallet = new Wallet(this.state.mnemonics, {
            discover: false
          });
          this.setState({ wallet }, () => {
            this.loadAddresses().then(() => {
              this.setState({ walletState: 2 });
            });
          });
        } catch {
          this.setState({ walletState: 3 });
        }
      }, 100);
    });
  };

  loadAddresses = async () => {
    console.log("Loading addresses");
    if (this.state.wallet) {
      const flo = this.state.wallet.getCoin("flo");
      const paymentReceiverAccount = flo.getAccount(0);
      const EXTERNAL_CHAIN = 0;
      await paymentReceiverAccount.getBalance({ discover: true });
      const addressesWithBalance = await paymentReceiverAccount.getAddresses(
        EXTERNAL_CHAIN
      );
      const addresses = addressesWithBalance.map(d => d.getPublicAddress());
      this.setState({
        addresses
      });
    }
  };

  loadTemplateDetails = async templateCode => {
    try {
      if (!this.state.templateDetails.hasOwnProperty(templateCode)) {
        const template = await api.getTemplate(templateCode);
        const fileDescriptor =
          template.data.results[0].template.file_descriptor_set;
        template.data.descriptor = decodeDescriptor(fileDescriptor);
        this.state.templateDetails[templateCode] = template.data;
        const extended = template.data.results[0].template.extends;
        if (extended) {
          extended.forEach(templateId => {
            const extendedCode = `tmpl_${templateId.toString(16)}`;
            !this.state.templateDetails.hasOwnProperty(extendedCode) &&
              this.loadTemplateDetails(extendedCode);
          });
        }
      }
      this.setState({ templatesLoaded: 1 });
    } catch (err) {
      this.setState({ templatesLoaded: -1 });
    }
  };

  render() {
    // Pass down the state and the onToggleLight action
    return (
      <MnemonicsContext.Provider
        value={{
          ...this.state,
          loadKeys: this.loadKeys,
          loadTemplateDetails: this.loadTemplateDetails,
          loadAddresses: this.loadAddresses
        }}
      >
        {this.props.children}
      </MnemonicsContext.Provider>
    );
  }
}

const MnemonicsStoreConsumer = MnemonicsContext.Consumer;

export { MnemonicsStoreProvider, MnemonicsStoreConsumer };
