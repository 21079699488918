import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { display } from "@material-ui/system";

import {
  Box,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

// "tmpl_636E68FA"

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

class Device extends React.Component {
  state = {
    update: true,
    manufacturer: undefined,
    manufacturerName: undefined,
    placeOfManufacture: undefined,
    model: undefined,
    serial: undefined,
    lot: undefined,
    dateOfDelivery: undefined,
    partList: [],
    partListNames: []
  };

  updateParent = () => {
    this.setState({ update: !this.state.update }, () => {
      const payload = {};
      if (this.state.update !== undefined) {
        payload["update"] = this.state.update;
      }
      if (this.state.model !== undefined) {
        payload["model"] = this.state.model;
      }
      if (this.state.manufacturer !== undefined) {
        payload["manufacturer"] = this.state.manufacturer;
      }
      if (this.state.placeOfManufacturer !== undefined) {
        payload["placeOfManufacturer"] = this.state.placeOfManufacturer;
      }
      if (this.state.serial !== undefined) {
        payload["serial"] = this.state.serial;
      }
      if (this.state.lot !== undefined) {
        payload["lot"] = this.state.lot;
      }
      if (this.state.dateOfDelivery !== undefined) {
        payload["dateOfDelivery"] = this.state.dateOfDelivery;
      }
      if (this.state.partList.length !== 0) {
        payload["partList"] = this.state.partList;
      }
      console.log("Updating parent");
      console.log(this.state);
      this.props.onChangePayload(payload);
    });
  };

  handleChange = name => event => {
    console.log(`updating ${name} to ${event.target.value}`);
    this.setState({ [name]: event.target.value }, this.updateParent());
  };

  handleChangeOip = (name, checkOip) => event => {
    const input = event.target.value;
    console.log(input);
    this.setState({ [name]: input }, () =>
      checkOip ? this.setName(name, checkOip) : this.updateParent()
    );
  };

  handleChangeOipList = (field, checkOip) => event => {
    const input = event.target.value.split("\n");
    while (input.indexOf("") !== -1) {
      const rm = input.indexOf("");
      input.splice(rm, 1);
    }
    this.setState({ [field]: input }, () =>
      checkOip ? this.setNames(field, checkOip) : this.updateParent()
    );
  };

  setName = async name => {
    console.log("setName");
    const id = this.state[name];
    console.log(id);
    const n = await this.oip2name(id);
    this.setState({ [`${name}Name`]: n }, () => this.updateParent());
  };

  setNames = async field => {
    console.log("setNames");
    const names = [];
    const ids = this.state[field];
    console.log(ids);
    for (let id of ids) {
      const name = await this.oip2name(id);
      if (name !== "") {
        names.push(name);
      }
    }
    this.setState({ [`${field}Names`]: names }, () => this.updateParent());
  };

  oip2name = id => {
    const oip = new OipApi();
    console.log(id);
    return oip.getRecord(id).then(record => {
      console.log(record);
      if (record.results) {
        if (
          record.results.length === 1 &&
          record.results[0].meta.txid === id &&
          record.results[0].hasOwnProperty("record")
        ) {
          const name =
            record.results[0].record.details[config.cardInfo.name.tmpl][
              config.cardInfo.name.name
            ];
          console.log(`record: ${JSON.stringify(name)}`);
          return name;
        }
        console.log(`no record found!`);
        return "";
      }
      console.log("not valid id");
      return "";
    });
  };

  renderValue = (field, label) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by--${field}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChange(field)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueOip = (field, label, checkOip = true) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChangeOip(field, checkOip)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueOipDisplay = (field, label, display) => {
    return (
      <Box display="flex" width={1} flexGrow={1} flexDirection="row">
        <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
          <Box>
            <Typography variant="caption">{display}</Typography>
          </Box>
        </Box>
        <Box p={1} width={3 / 4} flexGrow={1}>
          {this.renderValueOip(field, label)}
        </Box>
      </Box>
    );
  };

  renderValueList = (field, label, checkOip = true) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChangeOipList(field, checkOip)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueListDisplay = (field, label, display) => {
    return (
      <Box display="flex" width={1} flexGrow={1} flexDirection="row">
        <Box p={1} pt={3} width={1 / 4} flexGrow={1} flexDirection="column">
          {display.map((d, i) => (
            <Box key={i} p={0}>
              <Typography variant="caption">{d}</Typography>
            </Box>
          ))}
        </Box>
        <Box p={1} width={3 / 4} flexGrow={1}>
          {this.renderValueList(field, label)}
        </Box>
      </Box>
    );
  };

  renderDefault = () => {
    return (
      <Box
        justifyContent="center"
        flexDirection={"column"}
        display="flex"
        flexGrow={1}
        width={1}
      >
        <Box
          justifyContent="center"
          flexDirection={({ xs: "column" }, { md: "row" })}
          display="flex"
          flexGrow={1}
          width={1}
        >
          {this.renderValueOipDisplay(
            "manufacturer",
            "Manufacturer",
            this.state.manufacturerName
          )}
        </Box>
        <Box
          justifyContent="center"
          flexDirection={({ xs: "column" }, { md: "row" })}
          display="flex"
          flexGrow={1}
          width={1}
        >
          <Box p={1} width={{ xs: 1 }}>
            {this.renderValue("placeOfManufacture", "Place of Manufacture")}
          </Box>
          <Box p={1} width={{ xs: 1 }}>
            {this.renderValue("model", "Model")}
          </Box>
          <Box p={1} width={{ xs: 1 }}>
            {this.renderValue("serial", "Serial")}
          </Box>
          <Box p={1} width={{ xs: 1 }}>
            {this.renderValue("lot", "Lot")}
          </Box>
        </Box>
        <Box p={1} width={{ xs: 1 }}>
          {this.renderValueListDisplay(
            "partList",
            "Part List",
            this.state.partListNames
          )}
        </Box>
      </Box>
    );
  };

  render() {
    const { component } = this.props;
    switch (component) {
      case "value":
        return this.renderValue();
      case "oip":
        return this.renderValueOip();
      case "oip-display":
        return this.renderValueOipDisplay();
      case "oip-list":
        return this.renderValueList();
      case "oip-list-display":
        return this.renderValueListDisplay();
      default:
        return this.renderDefault();
    }
  }
}

Device.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePayload: PropTypes.func,
  component: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string
};

export default withStyles(styles)(Device);
