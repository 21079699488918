import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { Link } from "react-router-dom";
import cardStyle from "assets/jss/material-kit-react/views/componentsSections/cardStyle.jsx";

import {
  CardMedia,
  CircularProgress,
  Link,
  Typography
} from "@material-ui/core";

class DDX extends React.Component {
  state = {
    details: {},
    imgRef: require("assets/img/ddx-placeHolder.png")
  };

  addDefaultSrc(ev) {
    ev.target.src = require("assets/img/ddx-placeHolder.png");
  }

  renderBasicString = () => {
    const { field, details, template } = this.props;
    // this.setState({ details });
    let content = details[template][field];
    if (this.props.sliceContentFn) {
      content = this.props.sliceContentFn(content);
    }
    return (
      details[template] && <Typography {...this.props}>{content}</Typography>
    );
  };

  renderListString = () => {
    const { classes, details, template, field } = this.props;
    // this.setState({ details });
    const list = details[template][field];
    return (
      details[template] &&
      list.map((item, i) => {
        console.log(item);
        return (
          <Typography {...this.props} key={i}>
            hi
          </Typography>
        );
      })
    );
  };

  renderThumbnail = () => {
    const { classes, details, template, ipfsUrl } = this.props;
    // this.setState({ details });
    if (
      details[template] &&
      details[template].avatar &&
      details[template].avatar["tmpl_1AC73C98"]
    ) {
      return (
        <CardMedia
          component="img"
          alt="Record Image"
          className={classes.media}
          maxHeight="50"
          width="150"
          src={`${ipfsUrl}${details[template].avatar["tmpl_1AC73C98"].thumbnailAddress}`}
          onError={this.addDefaultSrc}
          title={details[template].name}
          href="/record"
        />
      );
    }
    return (
      <img
        src={this.state.imgRef}
        alt="avatar"
        style={{ maxHeight: "50px", width: "50px" }}
      ></img>
    );
  };

  renderImage = () => {
    const { classes, details, template, ipfsUrl } = this.props;
    // this.setState({ details });
    if (
      details[template] &&
      details[template].avatar &&
      details[template].avatar["tmpl_1AC73C98"]
    ) {
      return (
        <CardMedia
          style={{ maxWidth: "250px" }}
          component="img"
          alt="Record Image"
          // className={classes.media}
          src={`${ipfsUrl}${details[template].avatar["tmpl_1AC73C98"].imageAddress}`}
          onError={this.addDefaultSrc}
          title={details[template].name}
        />
      );
    }
    return (
      <img
        src={this.state.imgRef}
        alt="avatar"
        style={{ maxWidth: "250px" }}
      ></img>
    );
  };

  renderOipRef = () => {
    const { details, template, field, linkToAnotherDdx } = this.props;
    // this.setState({ details });
    const oipRef = details[template][field];
    if (details[template]) {
      if (linkToAnotherDdx) {
        return (
          <Link
            href={`${linkToAnotherDdx}/record/${oipRef.id}`}
            target="_blank"
            rel="noreferrer noopener"
            variant="body2"
          >{oipRef["tmpl_20AD45E7"].name}
          </Link>
        );
      }
      return (
        <Typography {...this.props}>{oipRef["tmpl_20AD45E7"].name}</Typography>
      );
    }
  };

  renderOipRefList = () => {
    const { classes, details, template, field, linkToAnotherDdx } = this.props;
    // this.setState({ details });
    console.log(details[template]);
    if (details[template] && details[template][field]) {
      return details[template][field].map((item, i) => {
        console.log(item);
        console.log("end");
        if (linkToAnotherDdx !== undefined) {
          return (
            <Link
              href={`${linkToAnotherDdx}/record/${item.id}`} // TODO: Refactor to just run renderOipRef in a loop.
              target="_blank"
              rel="noreferrer noopener"
              key={i}
              variant="body2"
            >
              - {item["tmpl_20AD45E7"].name}
            </Link>
          );
        }
        return (
          <Typography {...this.props} key={i}>
            - {item["tmpl_20AD45E7"].name /* JSON.stringify(item) */}
          </Typography>
        );
      });
    }
    return <p></p>;
  };

  waiting = () => <p>loading...</p>;
  loadingImage = () => <CircularProgress color="primary" size={20} />;

  render() {
    const { component, details, template } = this.props;
    switch (component) {
      case "basic":
        return details[template] ? this.renderBasicString() : this.waiting();
      case "thumb":
        return details[template] ? this.renderThumbnail() : this.loadingImage();
      case "image":
        return details[template] ? this.renderImage() : this.loadingImage();
      case "oip":
        return details[template] ? this.renderOipRef() : this.waiting();
      case "oip-list":
        return details[template] ? this.renderOipRefList() : this.waiting();
    }
  }
}

DDX.propTypes = {
  details: PropTypes.object.isRequired,
  template: PropTypes.string,
  component: PropTypes.string,
  classes: PropTypes.object,
  ipfsUrl: PropTypes.string,
  sliceContentFn: PropTypes.func,
  linkToAnotherDdx: PropTypes.string,
  field: PropTypes.string
};

export default withStyles(cardStyle)(DDX);
