import { config } from "../ddx.config";
import { Logger } from "loglevel-colored-prefix";
import { recordProtoBuilder, decodeDescriptor } from "oip-protobufjs";

import { OipApi } from "./OipApi";

class Publisher {
  constructor(wif, floNetwork, loglevel = "debug") {
    this.wif = wif;
    this.floNetwork = floNetwork;
    this.base = config.oip.daemonApiUrl;
    this.log = new Logger(loglevel);
    this.api = new OipApi(loglevel);
  }

  person(data) {
    const log = this.log.getLogger("Publisher::person");
    const personTemplateName = "tmpl_B6E9AF9B";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === personTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.debug(data.dateOfBirth);
    const dateOfBirth = new Date(data.dateOfBirth).getTime() / 1000;
    log.debug(dateOfBirth);
    const payload = {
      name: data.name,
      surname: data.surname,
      avatar: data.avatarOipRef,
      description: data.description,
      placeOfBirth: data.placeOfBirth,
      dateOfBirth
    };

    const detailsData = [
      { descriptor: basicDescriptor, payload, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload,
        name: personTemplateName
      }
    ];

    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  image(data, owner) {
    const log = this.log.getLogger("Publisher::avatar");
    log.info(data);
    const imageTemplateName = "tmpl_1AC73C98";
    const imageTemplateDescriptor = config.publishing.templates.find(
      t => t.name === imageTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;

    const payload = {
      name: `Avatar`,
      description: `Avatar published using ${config.title} for record of ${owner}`,
      thumbnailAddress: data.thumb,
      imageAddress: data.image
      // network: data.imgNetwork
    };
    log.info(payload);
    const detailsData = [
      { descriptor: basicDescriptor, payload, name: basicName },
      {
        descriptor: imageTemplateDescriptor,
        payload,
        name: imageTemplateName
      }
    ];
    log.info(detailsData);
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  sample(data) {
    const log = this.log.getLogger("Publisher::sample");
    const sampleTemplateName = "tmpl_636E68FA";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === sampleTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.info(data.basic);
    log.info(data.sample);
    const detailsData = [
      { descriptor: basicDescriptor, payload: data.basic, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload: data.sample,
        name: sampleTemplateName
      }
    ];
    log.info(detailsData);
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  protocol(data) {
    const log = this.log.getLogger("Publisher::protocol");
    const protocolTemplateName = "tmpl_2EF80254";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === protocolTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.info(data.basic);
    log.info(data.protocol);
    const detailsData = [
      { descriptor: basicDescriptor, payload: data.basic, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload: data.protocol,
        name: protocolTemplateName
      }
    ];
    log.info(detailsData);
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  organization(data) {
    const log = this.log.getLogger("Publisher::organization");
    const organizationTemplateName = "tmpl_05E5EEA3";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === organizationTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.info(data.basic);
    log.info(data.organization);
    const detailsData = [
      { descriptor: basicDescriptor, payload: data.basic, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload: data.organization,
        name: organizationTemplateName
      }
    ];
    log.info(detailsData);
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  async pack(data) {
    const log = this.log.getLogger("Publisher::record");
    log.warn(`I am here now`);
    const templateCodes = Object.keys(data).filter(k => data.hasOwnProperty(k));
    const detailsData = [];
    for (const code of templateCodes) {
      log.info(`Gathering information about the template ${code}`);
      const template = await this.api.getTemplate(code);
      const descriptor = template.data.results[0].template.file_descriptor_set;
      log.info(`Descriptor: ${descriptor}`);
      // const descriptor = decodeDescriptor(fileDescriptor);
      const name = template.data.results[0].template.name;
      detailsData.push({
        descriptor,
        payload: data[code],
        name
      });
    }
    log.info(`Parsed data`);
    log.info(detailsData);
    const protoRecord = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });
    const message = `p64:${protoRecord.signedMessage64}`;
    log.info(message);
    return message;
  }
}

export { Publisher };
