/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Box, Link, withStyles, Typography } from "@material-ui/core";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

import { config } from "ddx.config.js";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <Box display="flex" displayPrint="none" flexDirection="column" justifyContent="center" width={1}>
        <Box display="flex" flexDirection="column" justifyContent="center" width={1} >
          <Box display="flex" width={1}  justifyContent="center" flexDirection={{xs: "column", sm:"row"}}>
            {config.footer.links.map((l, i) => (
              <Link
                to={l.to}
                className={classes.block}
              >
                {l.name}
              </Link>
            ))}
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography className={classes.block}>
              Funding
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection={{xs: "column", sm:"row"}} flexShrink={1}>
                {config.footer.funding.map((l, i) => (
                  <Box p={1} flexShrink={1}>
                    <Link
                      href={l.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img width={l.width || "100%"} alt={l.name} src={l.logo} />
                    </Link>
                  </Box>
                ))}
            </Box>
          </Box>
      </Box>
        <Box display="flex" width={1} justifyContent="center" alignItems="center" flexDirection="column">
          <Typography className={classes.block} >
            {1900 + new Date().getYear()} , made with{" "}
            <Favorite className={classes.icon} /> for a better web.
          </Typography>
          <Box display="flex" flexDirection={{xs: "column", sm:"row"}} justifyContent="center" width={1}>
            {config.footer.authors.map((l, i) => (
              <Box p={1} alignSelf="center">
                <Link
                  href={l.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width={l.width || "100%"} alt={l.name} src={l.logo} />
                </Link>
              </Box>
            ))}
          </Box>
          </Box>
        <Box display="flex" width={1} justifyContent="center" alignItems="center" flexDirection="column">
          <Box p={1} alignSelf="center">
            <Link
              href={config.footer.license.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width={config.footer.license.width || "100%"} alt={config.footer.license.name} src={config.footer.license.badge} />
            </Link>
          </Box>
        </Box>
      </Box>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
