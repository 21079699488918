import React from "react";
import QRcode from "qrcode.react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import recordPageStyle from "assets/jss/material-kit-react/views/recordPage.jsx";
import PrintIcon from "@material-ui/icons/Print";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Box, Grid, IconButton, Snackbar, SvgIcon } from "@material-ui/core";

import { OipRecord } from "oip/OipRecord";
import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { MnemonicsStoreConsumer } from "../../MnemonicsStore.jsx";

import { DDX } from "components/ddx/ddx";

const api = new OipApi(config.daemonApiUrl);
const dashboardRoutes = [];

class RecordPage extends React.Component {
  constructor(data) {
    super();
    this.oipref = data.match.params.id;
    this.state = {
      details: {},
      showQR: false,
      txid: "",
      sig: "",
      name: "",
      description: "",
      location: "",
      tagList: [],
      noteList: [],
      parentOrganizationList: [],
      error: false,
      errorMessage: "we cannot find parts of this record."
    };
  }

  async componentDidMount() {
    const oip = new OipRecord();
    await api
      .getRecord(this.oipref)
      .then(data => {
        try {
          this.setState({ sig: data.results[0].meta.signed_by });
        } catch (err) {
          console.error(err);
        }
        return oip.resolveAll(data.results[0]);
      })
      .then(details => {
        console.log(JSON.stringify(details, null, " "));
        this.setState({ details });
      });
  }

  genQr() {
    this.setState({ showQR: !this.state.showQR });
  }

  render() {
    console.log(this.oipref);
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          displayPrint="none"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax className={classes.small}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>{this.state.name}</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          style={{ width: "100%" }}
        >
          <SnackbarContent
            message={
              <span>
                <b>Warning</b> {this.state.errorMessage}
              </span>
            }
            color="danger"
            close
            icon="info_outline"
          />
        </Snackbar>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Box
            pt={2}
            px={3}
            display="flex"
            flexGrow={1}
            flexDirection="row"
            displayPrint="none"
          >
            <Box flexGrow={1}>
              {this.context.addresses.includes(this.state.sig) && (
                <VpnKeyIcon
                  style={{ color: "darkgreen" }}
                  fontSize="small"
                ></VpnKeyIcon>
              )}
            </Box>
            <IconButton size="small" onClick={() => this.genQr()}>
              <SvgIcon>
                <g
                  transform="scale(.8,.8)"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="butt"
                >
                  <path id="A" d="M3,3 h8 v8 h-8 v-8 z M6,7 h2" />
                  <use xlinkHref="#A" x="18" y="0" />
                  <use xlinkHref="#A" x="0" y="18" />
                  <path d="M18,3 h-3 v4 h2 v4 h-2 v3" />
                  <path d="M2,15 h2 m2,0 h4 m6,0 h2 m2,0 h4 m4,0 h2" />
                  <path d="M4,17 h2 m2,0 h8 m6,0 h2 m2,0 h4" />
                  <path d="M14,19 h2 m2,0 h2 m6,0 h2" />
                  <path d="M16,21 h2 m2,0 h8" />
                  <path d="M14,23 h2 m2,0 h2 m4,0 h2 m2,0 h2" />
                  <path d="M14,25 h4 m2,0 h2 m2, 0 h4" />
                  <path d="M14,27 h2 m6,0 h2 m4,0 h2" />
                  <path d="M16,29 h8 m2,0 h2" />
                </g>
              </SvgIcon>
            </IconButton>
            <IconButton size="small" onClick={() => window.print()}>
              <PrintIcon></PrintIcon>
            </IconButton>
          </Box>
          <div className={classes.container} id="record">
            <Box
              pb={2}
              display={this.state.showQR ? "flex" : "none"}
              displayPrint="flex"
              margin="auto"
              justifyContent="center"
              width="100%"
              zIndex="10"
            >
              <QRcode value={this.oipref}></QRcode>
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <Box flex={1} flexDirection={"column"} spacing={1} width="1/2">
                <DDX
                  color="textPrimary"
                  variant="h5"
                  field="name"
                  component="basic"
                  template={config.templates.basic}
                  details={this.state.details}
                />
                <h3
                  className={classes.title}
                  style={{
                    marginTop: "0px",
                    marginBottom: "-10px"
                  }}
                >
                  {this.state.name}
                </h3>
                <Typography
                  style={{
                    fontSize: "8px",
                    overflowWrap: "break-word",
                    fontStyle: "italic"
                  }}
                  className={classes.root}
                >
                  <Link
                    href={`${config.floExplorer.url}/tx/${this.oipref}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {this.oipref}
                  </Link>
                </Typography>
                <p></p>
                <label className={classes.meta}>Description: </label>
                <p>
                  <DDX
                    // noWrap={true}
                    component="basic"
                    field="description"
                    template={config.templates.basic}
                    details={this.state.details}
                  />
                </p>
                <Box display="flex" flexDirection="row">
                  <Box width={1 / 2}>
                    <label component="label" className={classes.meta}>
                      Model:{" "}
                    </label>
                    <DDX
                      // noWrap={true}
                      component="basic"
                      field="model"
                      template={config.templates.device}
                      details={this.state.details}
                      style={{ display: "inline-block" }}
                      variant="body2"
                    />
                  </Box>
                  <Box width={1 / 2}>
                    <label component="label" className={classes.meta}>
                      Serial Number:{" "}
                    </label>
                    <DDX
                      // noWrap={true}
                      component="basic"
                      field="serial"
                      template={config.templates.device}
                      details={this.state.details}
                      style={{ display: "inline-block" }}
                      variant="body2"
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box display="flex" flexDirection="column" width={1 / 2}>
                    <label className={classes.meta}>Manufacturer: </label>
                    <DDX
                      // noWrap={true}
                      component="oip"
                      field="manufacturer"
                      template={config.templates.device}
                      linkToAnotherDdx={config.gateways.organizations}
                      details={this.state.details}
                    />
                    <label className={classes.meta}>Part List: </label>
                    <DDX
                      // noWrap={true}
                      component="oip-list"
                      field="partList"
                      template={config.templates.device}
                      linkToAnotherDdx=""
                      details={this.state.details}
                    />
                    <label className={classes.meta}>Notes: </label>
                    <Box p={1}>
                      {this.state.noteList.map((step, key) => {
                        return (
                          <Box py={1} key={key}>
                            <Typography
                              variant="body2"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              - {step}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    <label className={classes.meta}>Tags: </label>
                    <Box p={1}>
                      <Typography variant="body2">
                        {this.state.tagList.map((tag, key) => {
                          return (
                            <span key={key}>
                              {tag}
                              {key === this.state.tagList.length - 1
                                ? "."
                                : ", "}
                            </span>
                          );
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Grid item xs={12} sm={6} md={8}></Grid>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <DDX
                  classeName={classes.media}
                  component="image"
                  ipfsUrl={config.ipfs.apiUrl}
                  template={config.templates.basic}
                  details={this.state.details}
                  style={{ maxWidth: "250px" }}
                />
              </Box>
            </Box>
          </div>
        </div>
        <Footer displayPrint="none" />
      </div>
    );
  }
}

RecordPage.contextType = MnemonicsStoreConsumer;

export default withStyles(recordPageStyle)(RecordPage);
