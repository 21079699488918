const mainTitle = "DD-Devices";
const specificTitle = "DDX-project";
const title = `${mainTitle} ${specificTitle}`;

const config = {
  mainColor: "rgb(0, 131, 188)",
  title: title,
  landing: {
    text: `Welcome to the DDX gateway of ${mainTitle}, a decentralized registry of devices based on blockchain technology.`,
    button: [
      {
        text: "Explore Database",
        to: "/browse"
      },
      {
        text: "Learn more",
        to: "/about"
      }
    ],
    disclosure: "* This gateway is NOT curated.",
    numberOfNewestRecordsToShow: 12,
    aboutTitle: `${mainTitle} is a Web 3.0 public database for devices`,
    aboutShortText: `All the data of ${mainTitle} is stored in two distributed networks. The data itself goes to the Interplanetary Filesystem (IPFS) and the metadata (or record) goes to the FLO blockchain using the Open Index Protocol.`,
    aboutImage: require("assets/img/one.png"),
    mainImage: require("assets/img/BD135_Transistor.jpg")
  },
  about: {
    main: `The distributed database of (${mainTitle}) is an ownerless and trustless registry of devices. It uses the Open Index Protocol specification to record data in the immutable transactions of the FLO blockchain.`,
    title: `About ${mainTitle}`,
    sections: [
      {
        type: "full",
        title: `${title} vs ${mainTitle}`,
        text: [
          `${mainTitle} is ownerless database of devices. Because it is ownerless, anyone can add a record to the database without asking permission.`,
          `${title} is one of the gateways that facilitates the interaction of humans and the ${mainTitle}. It can be used to visualize records from ${mainTitle} and to add new records to it.`,
          `There are other gateways to ${mainTitle}, for example people.oip.io is another ${mainTitle} gateway mantained by Alexandria Labs. Each gateway can choose which record, or type of records it shows. This particular gateway is controled by the ${specificTitle}. That means that even if you use this gateway to add a record on ${mainTitle}, it may not be displayed by ${title}. That does not mean that your record is not on ${mainTitle}.`
        ],
        image: ""
      },
      {
        type: "full",
        title: `What is new about ${mainTitle}?`,
        text: [
          `${mainTitle} is a truly public database of devices.`,
          `There is no public space on the Internet. Even apparent free services that allow us to host content or add our pictures in social media are stored and processed in private infrastructure. However, the software initially developed as a digital currency relies on adding links to an immutable chain of information. It turned out that anyone can add links to that chain, making it a public space on the Internet.`
        ],
        image: ""
      },
      {
        type: "left",
        title: "Blockchain beyond digital currency",
        text: [
          "While the original Bitcoin was meant to be a digital currency, other blockchains have different purposes. The purpose of the FLO blockchain, called Florincoin initially, is to leverage the public space generated by blockchains to hold data, truly public data. The Open Index Protocol was created to capitalize on this property of the FLO blockchain."
        ],
        image: require("assets/img/btc-flo.png"),
        button: {
          text: "Learn more",
          href: "https://flo.cash"
        }
      },
      {
        type: "right",
        title: "The Open Index Protocol",
        text: [
          `The Open Index Protocol (OIP) is a series of specifications of how to read and write information applied to the blockchain. Around this protocol, we built programs that scan the blockchain and index information in local databases.`,
          `We centralized the index of ${mainTitle} in the distributed FLO blockchain and federated the ability to facilitate the consumption of data.`
        ],
        image: require("assets/img/oip.png"),
        button: {
          text: "Learn more",
          href: "https://openindexprotocol.com"
        }
      },
      {
        type: "full",
        title: "You own your record and your data.",
        text: [
          `When you submit a record to ${mainTitle} you must execute a blockchain transaction, we add the "Add Records" page to help. To execute a transaction, you need to sign the trasnaction with a valid cryptographic key that holds a FLO balance. You need that balance to pay miners to process your transaction and add to the blockchain for you.`,
          "Yes, anyone can be a miner. No, you can not pick which miner will process your transaction.",
          "By signing the transaction with your cryptographic key, the Open Index Protocol specification dictates that you own that transaction and consequently the record, you are the sole responsible individual for its content."
        ]
      },
      {
        type: "right",
        title: "Gateways are autonomous and independent.",
        text: [
          `Any gateway for ${mainTitle} has access to all the records, but they can choose what records to display, afterall it is their gateways.`,
          `Like this one, there are a number of gateways for ${mainTitle} with different levels of scrutiny to display records.`
        ],
        image: require("assets/img/oip.png"),
        button: {
          text: "Learn more",
          href: "https://openindexprotocol.com"
        }
      }
    ],
    faq: [
      {
        question: "Why my new record is not showing?",
        answer: "Because you need to get it approved first."
      },
      {
        question: "How do I get FLO?",
        answer: "You can buy FLO from Bittrex or from someone."
      },
      {
        question: "What is a mnemonics ?",
        answer: "Funny you asked. Mnemonics is..."
      },
      {
        question: "What are the terms and conditions?",
        answer: "This is all public data."
      }
    ]
  },
  headerLinks: {
    keys: {
      name: "Keys",
      to: "/keys"
    },
    items: [
      {
        name: "Explore Database",
        to: "/browse"
      },
      {
        name: "Add Devices",
        to: "/add"
      },
      {
        name: "About",
        to: "/about"
      }
    ]
  },
  footer: {
    links: [],
    funding: [
      {
        name: "Templelton Foundation",
        logo: require("assets/img/templeton-logo.png"),
        href: "https://www.templeton.org/",
        width: "100px"
      },
      {
        name: "NIH",
        logo: require("assets/img/nih.logo.black.png"),
        href: "https://www.nih.gov",
        width: "75px"
      },
      {
        name: "Moore Foundation",
        logo: require("assets/img/logo-moore-black.png"),
        href: "https://www.moore.org",
        width: "100px"
      }
    ],
    authors: [
      {
        text: "Jensen Lab",
        logo: require("assets/img/jensenLab.logo.black.png"),
        width: "100px"
      },
      {
        text: "Alexandria",
        logo: require("assets/img/alexandria.logo.black.png"),
        width: "100px"
      }
    ],
    license: {
      name: "CC-BY-SA",
      badge: require(`assets/img/cc-by-nc.png`),
      href: "https://creativecommons.org/licenses/by-nd/4.0/",
      width: "80px"
    }
  },
  ipfs: {
    apiUrl: "https://ipfs.io/ipfs/"
  },
  floExplorer: {
    url: "https://livenet.flocha.in/"
  },
  oip: {
    perPage: 12,
    daemonApiUrl: "https://api.oip.io/oip/o5/",
    baseTemplate: "tmpl_4FEB49C9",
    baseTemplateTmp: "tmpl_05E5EEA3",
    requiredTemplates: [],
    addressesWhiteList: [
      // "FJ6P5kU1FCaWZGR8GXfkbZFic1szEatwnG",
      // "FEQXuxEgfGoEnZbPCHPfHQDMUqXq6tpw4X",
      // "FB41shJxHWphSMp2kG9atqSZ4yWVqUWsaH",
      // "FUKensEGaUdrAvdwF3L3izpFkQdD2HrEg5"
      // "FHhiFfEzUxf7bEP5o1A4mzzA5ma5tHTNV3"
    ] // "F6R95XtThjfDr2uGgPPAyG3QS2749osmdC" */
  },
  templates: {
    basic: "tmpl_20AD45E7",
    organization: "tmpl_05E5EEA3",
    device: "tmpl_4FEB49C9"
  }
};

config.confirmation = {
  title: `You are about to submit your records to ${mainTitle}`,
  body: [
    `Submiting a record to ${config.title} requires that we broadcast a series of transactions in the FLO blockchain on your behalf.`,
    `This action is irreversible.`
  ],
  finalWords: ["Please confirm."],
  yes: "Agree",
  no: "Go back"
};

const suggestedTip = 0.03;

config.tip = {
  use: false,
  defaultCheck: true,
  suggestedTip,
  mainMsg: `I agree to donate ${suggestedTip} FLOs to support development of DDX and related projects`,
  textLinkForMore: `Learn More`,
  extraInfo: "We will split the funds in three outputs: FLO, OIP and DDX.",
  addresses: [
    {
      name: "ddx",
      id: "F9UqXcT1ujsVprMxe2CPQtLF6E8phEhfyg"
    },
    {
      name: "flo",
      id: "F9UqXcT1ujsVprMxe2CPQtLF6E8phEhfyg"
    },
    {
      name: "oip",
      id: "FLuUH6Dtcu4ZYYfGpdb7dLosytHhPVTA93"
    }
  ]
};

config.afterPost = {
  success: {
    title: `Success`,
    body: [`Your record to ${config.title} was successful.`],
    finalWords: [],
    button: "Cheers"
  },
  fail: {
    title: `Fail`,
    body: [
      `There was a problem sending your ${mainTitle} record to the blockchain.`,
      `Insufficient funds or lack of internet connection are common problems.`
    ],
    finalWords: [
      `Make sure you have enough funds and your device has internet connection and try again.`,
      `If not, contact the administrator of this page.`
    ],
    button: "Try again"
  }
};

config.pallete = {
  ddMain: {
    backgroundColor: config.mainColor,
    color: "#fff",
    boxShadow: `0 2px 2px 0 ${config.mainColor.replace(
      ")",
      ","
    )} 0.14), 0 3px 1px -2px ${config.mainColor.replace(
      ")",
      ","
    )} 0.2), 0 1px 5px 0 ${config.mainColor.replace(")", ",")} 0.12)`,
    "&:hover,&:focus": {
      backgroundColor: config.mainColor,
      boxShadow: `0 14px 26px -12px ${config.mainColor.replace(
        ")",
        ","
      )} 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px ${config.mainColor.replace(
        ")",
        ","
      )} 0.2)`
    },
    cardHeader: {
      color: "#fff",
      background: config.mainColor
    }
  }
};

config.gateways = {
  people: "https://people.ddx.rocks",
  protocol: "https://protocol.ddem.caltech.edu",
  //software: "https://software.ddem.caltech.edu",
  //materials: "https://materials.ddem.caltech.edu",
  devices: "https://devices.ddx.rocks",
  organizations: "https://org.ddx.rocks"
};

export { config };
